<template>
  <v-dialog v-model="dialog" max-width="500px" content-class="rounded-20">
    <v-card flat>
      <v-card-title class="off">
        <span class="text-h5">Delete File </span>
      </v-card-title>

      <v-card-text class="py-5">
        Are you sure you want to delete this file?
      </v-card-text>

      <v-card-actions class="off">
        <v-spacer></v-spacer>
        <v-btn
          class="btn-error"
          text
          :disabled="loading"
          @click="dialog = false"
        >
          Close
        </v-btn>
        <v-btn class="btn-primary" :loading="loading" @click="remove()">
          Delete
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DeleteModal",

  props: ["value", "loading"],

  computed: {
    dialog: {
      get() {
        return this.value
      },

      set(value) {
        this.se = ""
        this.$emit("input", value)
      }
    }
  },

  methods: {
    remove() {
      this.$emit("delete")
    }
  }
}
</script>
