<template>
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn :color="buttonColor" elevation="0" v-bind="attrs" v-on="on">
          <v-icon class="mr-2">mdi-update</v-icon>
          {{ selectedOrderSession.session_type }}
          <v-icon class="ml-2"> mdi-chevron-down </v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-subheader>CASE SESSIONS</v-subheader>
        <v-divider />
        <v-sheet v-for="(session, index) in sortedOrderSessions" :key="index">
          <v-list-item two-line @click="changeSession(session)">
            <v-list-item-icon class="mt-4">
              <v-icon v-if="session.uid !== selectedOrderSession.uid">
                mdi-update
              </v-icon>
              <v-icon v-else> mdi-check </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{
                toTitleCase(session.session_type)
              }}</v-list-item-title>
              <v-list-item-subtitle>
                {{ session.created_at | luxon({ output: { format: "med" } }) }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
        </v-sheet>
        <v-list-item
          @click="newSession = true"
          v-if="
            selectedOrder.sessions.length < currentPlan.order_session_limit &&
            !persistTab
          "
        >
          <v-list-item-icon>
            <v-icon>mdi-plus</v-icon>
          </v-list-item-icon>
          <v-list-item-title> Add New Session </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-dialog max-width="500" content-class="rounded-20" v-model="newSession">
      <v-card class="rounded-20">
        <v-card-title>New Case Session</v-card-title>
        <v-card-text>
          <v-form ref="newSessionForm" @submit.prevent>
            <p>Please enter a descriptive name for your session.</p>
            <v-text-field
              v-model="newSessionType"
              label="Session Description"
              hint="i.e. Immediate or Definitive"
              :rules="rule"
              outlined
              autofocus
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="btn-success"
            @click="createNewSession()"
            :disabled="loading"
          >
            <v-icon>mdi-save</v-icon>
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { toTitleCase } from "@/utils"
import client from "@/lib/ApiClient"

export default {
  name: "SessionSwitcher",

  props: ["persistTab", "color"],

  data() {
    return {
      loading: false,
      newSession: false,
      newSessionType: "",
      rule: [(v) => !!v || "Required"]
    }
  },

  computed: {
    ...mapGetters(["selectedOrder", "selectedOrderSession", "currentPlan"]),

    buttonColor() {
      if (this.color) return this.color
      return "primary"
    },

    sortedOrderSessions() {
      const sessions = [...this.selectedOrder.sessions].sort((a, b) =>
        b.created_at.localeCompare(a.created_at)
      )

      if (this.persistTab)
        return sessions.filter((session) => session.associated)
      return sessions
    }
  },

  methods: {
    ...mapActions(["setSelectedOrderSession", "triggerReload"]),

    toTitleCase,

    async createNewSession() {
      if (!this.$refs.newSessionForm.validate()) return
      this.loading = true
      const session = await client.instance.orders.createNewOrderSession(
        this.selectedOrder.uid,
        {
          session_type: this.newSessionType
        }
      )
      this.selectedOrder.sessions.push(session)
      this.newSession = false
      this.setSelectedOrderSession(session)
      this.loading = false
    },

    changeSession(session) {
      this.setSelectedOrderSession(session)
      this.triggerReload("forwardedOrders")
      this.triggerReload("files")
      this.triggerReload("exports")
      if (this.$route.query.tab !== "overview" && !this.persistTab)
        this.$router.push({ query: { tab: "overview" } })
    }
  }
}
</script>
