<template>
  <div>
    <ChooseOrder
      v-if="!forwardedOrderForm.product_code"
      @chooseOrder="chooseOrder"
      @cancelled="cancel"
    />
    <DesignOnlyOrder
      v-if="forwardedOrderForm.product_code === 'DESIGN'"
      @back="chooseOrder(null)"
      @toExport="$emit('toExport')"
      v-model="forwardedOrderForm"
    />
    <MilledBarOrder
      v-if="forwardedOrderForm.product_code === 'BAR'"
      @back="chooseOrder(null)"
      @toExport="$emit('toExport')"
      v-model="forwardedOrderForm"
    />
    <FinishedProsthesisOrder
      v-if="forwardedOrderForm.product_code === 'FINISHED-CASE'"
      @back="chooseOrder(null)"
      @toExport="$emit('toExport')"
      v-model="forwardedOrderForm"
    />
    <v-dialog
      max-width="550px"
      persistent
      v-model="exportDialog"
      content-class="rounded-20"
    >
      <v-card>
        <v-card-title class="off mb-2">
          <h2 class="title-h2 primary--text">ORDER REQUIRES EXPORT</h2>
        </v-card-title>
        <v-card-text class="pb-0">
          <p>
            An abutment export is required to continue with a
            {{ dialogProduct }} order.
          </p>
          <p>
            Please navigate to the export tab by selecting 'Export Abutment
            Positions'.
          </p>
        </v-card-text>
        <v-card-actions class="off">
          <v-spacer />
          <v-btn class="btn-error" @click="exportDialog = false"> Back </v-btn>
          <v-btn class="btn-primary" @click="$emit('toExport')">
            EXPORT ABUTMENT POSITIONS
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      max-width="550px"
      persistent
      v-model="orderDesignDialog"
      content-class="rounded-20"
    >
      <v-card>
        <v-card-title class="off mb-2">
          <h2 class="title-h2 primary--text">ORDER REQUIRES DESIGN ORDER</h2>
        </v-card-title>
        <v-card-text class="pb-0">
          <p>
            To proceed with a MILLED BAR order, a DESIGN order must be
            submitted.
          </p>
          <p>
            Please submit a DESIGN order by completing the DESIGN order options.
          </p>
        </v-card-text>
        <v-card-actions class="off">
          <v-spacer />
          <v-btn class="btn-error" @click="orderDesignDialog = false">
            Back
          </v-btn>
          <v-btn class="btn-primary" @click="chooseOrder('DESIGN')">
            <v-icon class="mr-2" large>$toothdesignsDark</v-icon>
            SUBMIT DESIGN ORDER
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      max-width="550px"
      persistent
      v-model="finishedDialog"
      content-class="rounded-20"
    >
      <v-card>
        <v-card-title class="off mb-2">
          <h2 class="title-h2 primary--text">EXISTING FINISHED CASE</h2>
        </v-card-title>
        <v-card-text class="pb-0">
          <p>
            There is an existing Finished Case for this order ({{
              selectedOrder.order_number
            }}). You will not be able to open another.
          </p>
          <p>Select 'OPEN EXISTING ORDER' to continue.</p>
        </v-card-text>
        <v-card-actions class="off">
          <v-spacer />
          <v-btn class="btn-error" @click="finishedDialog = false">
            Back
          </v-btn>
          <v-btn class="btn-primary" @click="openFinishedCase">
            <v-icon class="mr-2" large>$finishedprostheticDark</v-icon>
            OPEN EXISTING ORDER
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import client from "@/lib/ApiClient"
import ChooseOrder from "./OrderComponent/ChooseOrder"
import MilledBarOrder from "./OrderComponent/MilledBarOrder"
import DesignOnlyOrder from "./OrderComponent/DesignOnlyOrder"
import FinishedProsthesisOrder from "./OrderComponent/FinishedProsthesisOrder"

export default {
  name: "OrderComponent",

  components: {
    ChooseOrder,
    MilledBarOrder,
    DesignOnlyOrder,
    FinishedProsthesisOrder
  },

  data() {
    return {
      exportDialog: false,
      dialogProduct: null,
      orderDesignDialog: false,
      finishedDialog: false
    }
  },

  computed: {
    ...mapGetters([
      "selectedOrder",
      "selectedOrderSession",
      "selectedForwardedOrder",
      "forwardedOrderForm",
      "featureFlags",
      "selectedOrderExports",
      "orderForwardedOrders"
    ])
  },

  methods: {
    ...mapActions([
      "setForwardedOrderForm",
      "setSelectedForwardedOrder",
      "triggerReload"
    ]),

    async chooseOrder(code) {
      if (!code) {
        this.setSelectedForwardedOrder(null)
        return
      }
      this.exportDialog = false
      this.dialogProduct = null
      this.orderDesignDialog = false
      // if !export and feature flag requires export - go to export
      // if BAR selected, no DESIGN order and feature flag requires DESIGN - go to DESIGN order
      // if FINISHED-CASE selected and existing FINISHED-CASE, open FINISHED-CASE
      // else continue to order selection setup
      if (
        this.featureFlags[`${code.toLowerCase()}_requires_export`] &&
        !this.selectedOrderExports
      ) {
        this.openExportDialog(code)
      } else if (
        code === "BAR" &&
        this.featureFlags.bar_requires_design &&
        !this.orderForwardedOrders.filter(
          (o) => o.submitted_at && o.product === "DESIGN"
        ).length
      ) {
        this.openDesignDialog()
      } else if (
        code === "FINISHED-CASE" &&
        this.orderForwardedOrders.filter(
          (o) =>
            o.product === "FINISHED-CASE" &&
            (o.oep_submission_uid || o.oep_component_uids.length)
        ).length
      ) {
        this.openFinishedDialog()
      } else {
        this.setForwardedOrderForm({
          ...this.forwardedOrderForm,
          product_code: code
        })
        if (!code && this.selectedForwardedOrder) {
          this.setSelectedForwardedOrder(null)
        } else {
          let array =
            this.selectedOrderSession.responses.find(
              (item) => item.widget === "tooth_picker"
            )?.value || []
          let implants = [...array]
          this.setForwardedOrderForm({
            ...this.forwardedOrderForm,
            implants: implants
          })
          const forwardedOrder =
            await client.instance.orders.createForwardedOrder(
              this.selectedOrder.uid,
              this.selectedOrderSession.uid,
              this.forwardedOrderForm
            )
          this.triggerReload("forwardedOrders")
          this.setSelectedForwardedOrder(forwardedOrder)
        }
      }
    },

    openExportDialog(code) {
      this.exportDialog = true
      this.dialogProduct = code
    },

    openDesignDialog() {
      this.orderDesignDialog = true
    },

    openFinishedDialog() {
      this.finishedDialog = true
    },

    openFinishedCase() {
      this.setSelectedForwardedOrder(
        this.orderForwardedOrders.filter(
          (o) => o.product === "FINISHED-CASE"
        )[0]
      )
      this.finishedDialog = false
    },

    cancel() {
      this.$emit("cancelled")
    }
  },

  mounted() {
    if (this.$route.params.forwardedOrder) {
      this.setSelectedForwardedOrder(this.$route.params.forwardedOrder)
    }

    this.triggerReload("exports")
  }
}
</script>
