<template>
  <v-hover v-slot="{ hover }">
    <div>
      <v-card
        height="175"
        width="175"
        :class="['rounded-10', 'blue-border', { 'btn-primary': hover }]"
        v-ripple
        @click="$emit('chooseOrder', 'FINISHED')"
      >
        <v-card-text
          class="px-1 text-center text-subtitle-1 pb-0"
          :style="{
            color: hover ? 'white' : '#147BD1'
          }"
        >
          <strong> FINISHED PROSTHESIS </strong>
        </v-card-text>
        <v-container fluid>
          <v-img
            contain
            :src="require('@/assets/final-prosthesis-bar-retained.png')"
            position="center center"
          ></v-img>
        </v-container>
      </v-card>
      <v-card flat v-if="hover" width="250px">
        <v-card-text class="text-left">
          <ul>
            <li>Completely finished Nexus Hybrid prosthesis.</li>
            <li>Try-In included in process.</li>
            <li>Ready for patient insert.</li>
          </ul>
        </v-card-text>
      </v-card>
    </div>
  </v-hover>
</template>

<script>
export default {
  name: "FinishedProsthesis"
}
</script>
