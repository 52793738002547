<template>
  <v-dialog v-model="dialog" width="max-content" content-class="elevation-0">
    <v-row class="ma-0 pa-0">
      <v-col class="ma-5">
        <PurchaseCard
          v-model="dialog"
          :item="item"
          :acquisitionMethod="acquisitionMethod"
          :loading="loading"
          @confirm="emitConfirm"
        />
      </v-col>
      <v-col class="ma-5" v-if="upgradeable">
        <UpgradeSuggestion @click.stop :item="item" />
      </v-col>
    </v-row>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex"
import PurchaseCard from "@/components/shared/PurchaseDialog/PurchaseCard.vue"
import UpgradeSuggestion from "@/components/shared/PurchaseDialog/UpgradeSuggestion.vue"
export default {
  props: ["item", "acquisitionMethod", "value", "loading"],
  components: { PurchaseCard, UpgradeSuggestion },
  computed: {
    ...mapGetters(["subscription", "plans", "currentPlan"]),
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      }
    },
    upgradeable() {
      if (this.acquisitionMethod === "quota") return false
      if (this.item.product_code === "GAUGEKIT-GEN2") return false

      const upgradeable = this.plans.some(
        (p) => p.subscription_price > this.currentPlan.subscription_price
      )
      return upgradeable
    }
  },
  methods: {
    emitConfirm(paymentMethodId) {
      this.$emit("confirm", paymentMethodId)
    }
  }
}
</script>
