<template>
  <v-card flat>
    <v-card-title class="title-font pb-1" style="font-size: medium">
      <v-icon
        v-if="!valid"
        :color="file ? 'success' : 'error'"
        class="mr-2"
        small
      >
        {{ file ? "mdi-check-circle" : "mdi-close-circle" }}
      </v-icon>
      {{ categoryTitle }}
      <small v-if="categorySubtitle" class="ml-3">
        {{ categorySubtitle }}
      </small>
    </v-card-title>
    <v-card-text>
      <div class="error--text pb-5" v-if="error">Scan Must be Supplied</div>
      <v-row v-if="fileUrl">
        <v-col cols="12">
          <ModelViewer
            :filename="file.name"
            :url="fileUrl"
            :height="264"
            :width="330"
            class="pa-0 ma-0"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <FileUploader
            v-model="file"
            text="Upload"
            fileType="scan"
            :category="category"
            :newOrder="true"
            :disabled="disabled"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import ModelViewer from "@/components/shared/ModelViewer.vue"
import FileUploader from "@/components/shared/FileUploadAndPreview/FileUploader.vue"

export default {
  name: "NewOrderScanUploader",

  props: ["value", "categoryTitle", "category", "categorySubtitle", "disabled"],

  inject: ["form"],

  components: { FileUploader, ModelViewer },

  data() {
    return {
      fileUrl: null,
      error: null,
      valid: true
    }
  },

  watch: {
    file(value) {
      if (value) {
        this.fileUrl = URL.createObjectURL(value)
      }
      if (!value) this.fileUrl = null
    }
  },

  computed: {
    file: {
      get() {
        return this.value
      },

      set(value) {
        if (value) {
          this.error = null
        }
        this.$emit("input", value)
      }
    }
  },

  methods: {
    validate() {
      if (!this.file) {
        this.error = "Must be selected"
        this.valid = false
      } else this.valid = true
      return !!this.file
    }
  },

  created() {
    this.form.register(this)
  }
}
</script>
