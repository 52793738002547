<template>
  <v-row>
    <v-col :cols="workflowStep > 1 ? 6 : 12">
      <v-card class="rounded-20 mx-4" flat>
        <v-row class="ma-0">
          <v-col cols="8" class="pa-0">
            <v-card-title class="title-font">
              <v-icon large class="mr-2">$finishedprosthetic</v-icon> FINISHED
              PROSTHESIS ORDER
              <v-progress-circular
                v-if="loading"
                indeterminate
                size="32"
                class="ml-5"
              />
            </v-card-title>
          </v-col>
          <v-col cols="4" class="pa-0">
            <v-card-text class="fill-width pb-0 d-flex justify-center">
              <v-img
                :src="require('@/assets/final-prosthesis-bar-retained.png')"
                class="mx-4"
                contain
                max-width="200"
              />
            </v-card-text>
          </v-col>
        </v-row>

        <v-card-text v-if="workflowStep === 1" class="pt-0">
          Continue your order by completing the necessary options.
        </v-card-text>

        <v-card-text v-if="workflowStep === 2" class="mt-2">
          Continue your order on the Nexus IOS ordering platform. Once
          submitted, the order details will be displayed below.
          <v-card-text>
            <v-card flat outlined class="rounded-10 pa-2 mb-3">
              <v-row>
                <v-col cols="12">
                  <v-icon size="30" class="mr-2">
                    {{
                      determineIconFromProduct(selectedForwardedOrder.product)
                    }}
                  </v-icon>
                  {{ toTitleCase(selectedForwardedOrder.product) }}
                  <v-chip label small color="primary" class="px-1 ml-2">{{
                    sessionName(selectedForwardedOrder.session_uid)
                  }}</v-chip>
                </v-col>
                <v-col cols="6">
                  {{
                    selectedForwardedOrder.created_at
                      | luxon({ output: "short" })
                  }}
                </v-col>
                <v-col cols="6" class="text-right">
                  <v-btn
                    class="btn-primary mr-2 pa-2"
                    @click="openNexusOrderingCase"
                  >
                    Continue
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-card-text>
        </v-card-text>

        <v-card-text v-if="workflowStep === 3" class="pt-0">
          <OrderSummary />
        </v-card-text>

        <div v-if="workflowStep === 1">
          <ForwardedOrderOptions
            @back="$emit('back')"
            @next="handleStepChange(++workflowStep)"
          />
        </div>
      </v-card>
    </v-col>
    <v-col v-if="workflowStep > 1">
      <CaseChat class="mr-4" />
    </v-col>
  </v-row>
</template>

<script>
import { toTitleCase } from "@/utils"
import { mapActions, mapGetters } from "vuex"
import ForwardedOrderOptions from "./FinishedProsthesisOrder/ForwardedOrderOptions"
import OrderSummary from "./FinishedProsthesisOrder/OrderSummary"
import CaseChat from "@/components/shared/CaseChat"
import client from "@/lib/ApiClient"

export default {
  name: "FinishedProsthesisCase",

  components: {
    CaseChat,
    ForwardedOrderOptions,
    OrderSummary
  },

  data() {
    return {
      loading: false,
      workflowStep: 1,
      dialog: false
    }
  },

  computed: {
    ...mapGetters([
      "selectedOrder",
      "paymentMethods",
      "selectedForwardedOrder",
      "forwardedOrderForm",
      "selectedOrderExports"
    ]),

    currentStep() {
      return this.exportComplete ? 2 : 1
    },

    exportComplete() {
      return !!this.selectedOrderExports?.length
    },

    acquisitionMethod() {
      return this.acquisitionStrategy?.find(
        (s) => s.product_code === "FINISHED-CASE"
      ).method
    }
  },

  methods: {
    ...mapActions([
      "setSelectedForwardedOrder",
      "setForwardedOrderForm",
      "setPaymentMethods"
    ]),

    handleStepChange(step) {
      this.workflowStep = step
    },

    async createForwardedOrder() {
      const forwardedOrder = await client.instance.orders.createForwardedOrder(
        this.selectedOrder.uid,
        this.forwardedOrderForm
      )
      this.setSelectedForwardedOrder(forwardedOrder)
    },

    async handleContinue() {
      this.loading = true
      await this.createForwardedOrder()
      this.workflowStep++
      this.loading = false
    },

    determineIconFromProduct(product_code) {
      switch (product_code) {
        case "BAR":
          return "$milledbar"
        case "DESIGN":
          return "$toothdesigns"
        case "FINISHED-CASE":
          return "$finishedprosthetic"
      }
    },

    sessionName(sessionUid) {
      return toTitleCase(
        this.selectedOrder.sessions.find((s) => s.uid === sessionUid)
          .session_type
      )
    },

    async openNexusOrderingCase() {
      const forwardedOrder = await client.instance.orders.getForwardedOrder(
        this.selectedOrder.uid,
        this.selectedForwardedOrder.session_uid,
        this.selectedForwardedOrder.uid
      )
      if (forwardedOrder.oep_submission_uid && !forwardedOrder.submitted_at) {
        window.open(
          `${process.env.VUE_APP_NEXUS_ORDERING_URL}/${forwardedOrder.oep_submission_uid}`,
          "_blank"
        )
      } else {
        this.setSelectedForwardedOrder(forwardedOrder)
        this.handleStepChange(3)
      }
    },

    async tabRefocus() {
      if (
        document.visibilityState === "visible" &&
        this.selectedForwardedOrder
      ) {
        this.loading = true
        const forwardedOrder = await client.instance.orders.getForwardedOrder(
          this.selectedOrder.uid,
          this.selectedForwardedOrder.session_uid,
          this.selectedForwardedOrder.uid
        )
        if (forwardedOrder.submitted_at) {
          this.setSelectedForwardedOrder(forwardedOrder)
          this.handleStepChange(3)
        }
        this.loading = false
      }
    },

    toTitleCase
  },

  async mounted() {
    document.addEventListener("visibilitychange", this.tabRefocus)

    if (this.selectedForwardedOrder) {
      if (this.selectedForwardedOrder.submitted_at) {
        this.handleStepChange(3)
      } else if (this.selectedForwardedOrder.oep_submission_uid) {
        this.handleStepChange(2)
      } else {
        this.handleStepChange(1)
      }
    }
  },

  beforeDestroy() {
    document.removeEventListener("visibilitychange", this.tabRefocus)
  }
}
</script>
