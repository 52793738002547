var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{staticClass:"transparent",attrs:{"flat":""}},[_c('v-card',{staticClass:"ma-4 rounded-20",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"title-font"},[_vm._v(" SCAN GAUGE SCAN LIBRARY ASSOCIATION ")]),_c('v-card-subtitle',[_vm._v(" Carefully select each Scan Gauge used in the kit with the corresponding gauge in the scan. Use the round-dimples to check that they match. ")])],1),_c('v-row',[(!_vm.selectedOrderSession.associated && !_vm.showFailure)?[_c('v-col',{attrs:{"cols":"5"}},[_c('GaugeSelection',{attrs:{"gauges":_vm.gauges,"libraryAssociation":_vm.selectedGaugeLibraries,"active":_vm.selectedScanGaugeIndex >= 0,"loading":_vm.loading},on:{"start":function($event){return _vm.scanGaugeSelect(0)},"delete":_vm.deleteAssociatedGauge},model:{value:(_vm.selectedKitGauge),callback:function ($$v) {_vm.selectedKitGauge=$$v},expression:"selectedKitGauge"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('SelectedGauge',{attrs:{"selectedKitGauge":_vm.selectedKitGauge,"selectedScanGaugeIndex":_vm.selectedScanGaugeIndex},on:{"confirm":_vm.confirmSelection}})],1)]:_vm._e(),(_vm.selectedOrderSession.associated || _vm.showFailure)?_c('v-col',{attrs:{"cols":"7"}},[_c('AssociationResult',{attrs:{"associationPass":_vm.associationPass,"loading":_vm.loading},on:{"redo":_vm.redoAssociation}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"5"}},[_c('v-card',{staticClass:"mr-4 rounded-20 d-flex flex-column",attrs:{"flat":"","height":"100%"}},[_c('v-card-title',{staticClass:"title-font"},[_vm._v(" SCAN GAUGE ALIGNMENT ")]),_c('v-card-text',{staticClass:"text-center",staticStyle:{"width":"100%","height":"415px"}},[(_vm.loading)?_c('v-row',{staticClass:"fill-height",attrs:{"justify":"center","align":"center"}},[_c('LoadingSpinner')],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],ref:"view"})],1),(
              !_vm.selectedOrderSession.associated &&
              !_vm.showFailure &&
              _vm.selectedGaugeLibraries.length
            )?_c('ScanSelectConfirm',{attrs:{"selectedGaugeLibraries":_vm.selectedGaugeLibraries,"selectedScanGaugeIndex":_vm.selectedScanGaugeIndex,"loading":_vm.loading},on:{"select":_vm.scanGaugeSelect,"confirm":function($event){_vm.dialog = true}}}):_vm._e(),(_vm.selectedOrderSession.associated || _vm.showFailure)?_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"ml-10"},[_c('div'),_c('v-switch',{staticClass:"mt-2",attrs:{"disabled":_vm.loading,"inset":"","dense":"","hide-details":"","label":"Scan Gauge Libraries"},model:{value:(_vm.showLibraries),callback:function ($$v) {_vm.showLibraries=$$v},expression:"showLibraries"}}),_c('v-switch',{staticClass:"mt-2",attrs:{"disabled":_vm.loading,"inset":"","dense":"","hide-details":"","label":"Constructed Libraries"},model:{value:(_vm.showConstructedLibraries),callback:function ($$v) {_vm.showConstructedLibraries=$$v},expression:"showConstructedLibraries"}})],1),_c('v-col',{class:{
                    'mr-10': true,
                    'ml-10': _vm.$vuetify.breakpoint.lgAndDown
                  }},[_c('v-switch',{staticClass:"mt-2",attrs:{"disabled":_vm.loading,"inset":"","dense":"","hide-details":"","label":"Scan Gauges"},model:{value:(_vm.showScanGauges),callback:function ($$v) {_vm.showScanGauges=$$v},expression:"showScanGauges"}}),_c('v-switch',{staticClass:"mt-2",attrs:{"disabled":_vm.loading,"inset":"","dense":"","hide-details":"","label":"Tissue Scan"},model:{value:(_vm.showScanTissue),callback:function ($$v) {_vm.showScanTissue=$$v},expression:"showScanTissue"}})],1)],1)],1)],1):_vm._e()],1)],1)],2)],1),_c('v-dialog',{attrs:{"max-width":"500px","persistent":"","content-class":"rounded-20"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"off mb-2"},[_c('h2',{staticClass:"title-h2 primary--text"},[_vm._v("CONFIRM LIBRARY ASSOCIATION")])]),_c('v-card-text',{staticClass:"pb-0"},[_c('p',[_vm._v(" Do you confirm each Scan Gauge from the scan is correctly associated with its corresponding library from the Scan Gauge Kit? ")]),_c('p',[_vm._v(" Incorrect library associations will be detrimental to any further processes for this case. ")]),(_vm.loading)?_c('p',[_c('strong',[_vm._v("Please be patient, this may take a few minutes...")])]):_vm._e()]),_c('v-card-actions',{staticClass:"off"},[_c('v-spacer'),_c('v-btn',{staticClass:"btn-error",attrs:{"disabled":_vm.loading},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Back ")]),_c('v-btn',{staticClass:"btn-primary",attrs:{"loading":_vm.loading,"disabled":_vm.loading},on:{"click":_vm.confirmAssociation}},[_vm._v(" Confirm ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }