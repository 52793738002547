<template>
  <v-btn
    width="100%"
    height="86"
    v-ripple
    outlined
    :color="dragOver ? 'primary' : ''"
    class="rounded-10 fill-height py-1 grey-border"
    style="cursor: pointer; user-select: none; background-color: #f5f5f5"
    @drop.prevent="onDrop($event)"
    @dragover.prevent="dragOver = true"
    @dragenter.prevent="dragOver = true"
    @dragleave.prevent="dragOver = false"
    @click="addFile()"
    :disabled="disabled || loading"
  >
    <div class="d-flex flex-column">
      <v-progress-circular
        v-if="loading"
        color="primary"
        :value="uploadProgress"
      />
      <span v-if="!loading">
        <v-icon class="mr-1">$upload</v-icon>
        UPLOAD
      </span>
      <i v-if="!loading" style="font-size: 10px; text-transform: none">
        Click or Drag and Drop to upload
      </i>
      <i
        v-if="!loading && (invalidFile || maxFilesExceeded)"
        style="font-size: 10px; text-transform: none"
        class="error--text"
      >
        <span v-if="maxFilesExceeded"> Only one file can be uploaded. </span>
        <span v-if="invalidFile && fileType === 'scan'">
          File type must be .stl or .ply
        </span>
        <span v-if="invalidFile && fileType === 'photo'">
          File type must be one of: .jpg, .jpeg, .png
        </span>
      </i>
    </div>
  </v-btn>
</template>

<script>
import { mapGetters } from "vuex"
import client from "@/lib/ApiClient"

export default {
  name: "FileUploader",

  props: ["text", "value", "category", "disabled", "newOrder", "fileType"],

  watch: {
    value(value) {
      if (value) {
        this.invalidFile = false
        this.filesToUpload = []
      }
    }
  },

  data() {
    return {
      loading: false,
      dragOver: false,
      filesToUpload: [],
      filesUploaded: [],
      failedUpload: [],
      uploadProgress: 0,
      inputDOMElement: null,
      invalidFile: false,
      maxFilesExceeded: false
    }
  },

  computed: {
    ...mapGetters(["selectedOrder", "selectedOrderSession", "country"])
  },

  methods: {
    fileExtensionCheck(file) {
      let ext = file.name.toLowerCase().split(".").pop()
      if (this.fileType === "other") return true

      switch (ext) {
        case "jpg":
        case "jpeg":
        case "png":
          return this.fileType === "photo"
        case "ply":
        case "stl":
          return this.fileType === "scan"
        default:
          return false
      }
    },

    fileTypeFromFile(file) {
      let ext = file.name.toLowerCase().split(".").pop()
      if (["stl", "ply"].includes(ext)) return "scan"
      if (["png", "jpg", "jpeg"].includes(ext)) return "photo"
      else return "other"
    },

    async addFile() {
      this.maxFilesExceeded = false
      this.invalidFile = false
      if (this.inputDOMElement) {
        this.inputDOMElement.value = ""
      } else {
        this.inputDOMElement = document.createElement("input")
        this.inputDOMElement.type = "file"
        this.inputDOMElement.multiple = false
        this.inputDOMElement.addEventListener("change", async (event) => {
          if (event.target.files.length > 1) {
            this.maxFilesExceeded = true
            return
          }

          const file = event.target.files[0]
          if (!this.fileExtensionCheck(file)) {
            this.invalidFile = true
            return
          }

          if (this.newOrder) this.$emit("input", file)
          else {
            this.loading = true
            await this.handleFileUpload(file)
            this.loading = false
          }
        })
      }
      this.inputDOMElement.click()
    },

    async onDrop(event) {
      this.dragOver = false
      this.maxFilesExceeded = false
      this.invalidFile = false

      if (event.dataTransfer.files.length > 1) {
        this.maxFilesExceeded = true
        return
      }

      const file = event.dataTransfer.files[0]
      if (!this.fileExtensionCheck(file)) {
        this.invalidFile = true
        return
      }

      if (this.newOrder) this.$emit("input", file)
      else {
        this.loading = true
        await this.handleFileUpload(file)
        this.loading = false
      }
    },

    async handleFileUpload(file) {
      if (!file) return
      this.uploadProgress = 0

      const uploadedFile = await client.oft.uploadFiles(
        file,
        (progressValue) => {
          this.uploadProgress = progressValue
        }
      )

      if (!uploadedFile.uploaded) {
        this.failedUpload.push(file)
        return
      }

      let payload = []
      payload.push({
        file_name: uploadedFile.oftFileName,
        file_category: this.category,
        file_type: this.fileType,
        oft_file_uid: uploadedFile.oftUid
      })

      const response = await client.instance.orders.addFiles(
        this.selectedOrder.uid,
        this.selectedOrderSession.uid,
        payload
      )

      this.$emit("input", response[0])
    }
  }
}
</script>
