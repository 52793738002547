<template>
  <div>
    <v-card v-if="workflowStep < 2" class="rounded-20 ma-4" flat>
      <v-row class="ma-0">
        <v-col cols="8" class="pa-0">
          <v-card-title class="title-font">
            <v-icon large class="mr-2">$milledbar</v-icon> MILLED BAR ORDER
            <v-progress-circular
              indeterminate
              v-if="loading"
              size="32"
              class="ml-5"
            />
          </v-card-title>
          <v-card-text v-if="workflowStep === 1">
            Continue your order by completing the necessary options.
          </v-card-text>
        </v-col>
        <v-col cols="4" class="pa-0">
          <v-card-text class="fill-width pa-0 d-flex justify-center">
            <v-img
              :src="require('@/assets/milled-bar-only.png')"
              class="ma-4"
              contain
              max-width="200"
            />
          </v-card-text>
        </v-col>
      </v-row>
      <div v-if="workflowStep == 1">
        <ForwardedOrderOptions
          @back="$emit('back')"
          @next="handleStepChange(++workflowStep)"
        />
      </div>
    </v-card>

    <v-row v-if="workflowStep == 2">
      <v-col cols="6">
        <v-card class="rounded-20 fill-height ml-4" flat>
          <v-row class="ma-0">
            <v-col cols="8" class="pa-0">
              <v-card-title class="title-font">
                <v-icon large class="mr-2">$milledbar</v-icon> MILLED BAR ORDER
              </v-card-title>
            </v-col>
            <v-col cols="4" class="pa-0">
              <v-card-text class="d-flex justify-center fill-width pb-0">
                <v-img
                  :src="require('@/assets/milled-bar-only.png')"
                  class="mx-4"
                  contain
                  max-width="200"
                  position="center right"
                />
              </v-card-text>
            </v-col>
          </v-row>
          <v-card-text class="pt-0">
            <OrderSummary />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <CaseChat v-if="workflowStep == 2" class="fill-height mr-4" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import client from "@/lib/ApiClient"
import CaseChat from "@/components/shared/CaseChat.vue"
import OrderSummary from "./MilledBarOrder/OrderSummary.vue"
import ForwardedOrderOptions from "./MilledBarOrder/ForwardedOrderOptions.vue"

export default {
  name: "MilledBarOrder",

  components: {
    CaseChat,
    OrderSummary,
    ForwardedOrderOptions
  },

  data() {
    return {
      workflowStep: 1,
      loading: false,
      lineItem: {
        description: "Milled Bar",
        amount: "2 000",
        currency: "AUD",
        quantity: 1,
        product_code: "BAR",
        quota_code: "bars"
      }
    }
  },

  computed: {
    ...mapGetters([
      "selectedOrder",
      "selectedForwardedOrder",
      "forwardedOrderForm",
      "selectedOrderExports"
    ]),

    exportComplete() {
      return !!this.selectedOrderExports
    },

    currentStep() {
      return this.exportComplete ? 2 : 1
    },

    acquisitionMethod() {
      return this.acquisitionStrategy?.find((s) => s.product_code === "BAR")
        .method
    }
  },

  methods: {
    ...mapActions([
      "setSelectedForwardedOrder",
      "setForwardedOrderForm",
      "triggerReload"
    ]),

    handleStepChange(step) {
      this.workflowStep = step
    },

    async createForwardedOrder() {
      const forwardedOrder = await client.instance.orders.createForwardedOrder(
        this.selectedOrder.uid,
        this.forwardedOrderForm
      )
      this.setSelectedForwardedOrder(forwardedOrder)
    },

    async handleContinue() {
      this.loading = true
      await this.createForwardedOrder()
      this.loading = false
      return this.workflowStep++
    }
  },

  async mounted() {
    if (this.selectedForwardedOrder) {
      if (this.selectedForwardedOrder.submitted_at) {
        this.handleStepChange(2)
      } else {
        this.handleStepChange(1)
      }
    }
  }
}
</script>
