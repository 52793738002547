<template>
  <v-card class="pb-2 px-2 rounded-20" width="550px">
    <v-card-title class="mb-1">
      <h2 class="title-h2">Confirm Order</h2>
    </v-card-title>
    <v-card-text class="pb-2">
      <p v-if="!currentTenant.activated" class="black--text">
        <strong> This transaction will activate your Subscription. </strong>
      </p>
      <p v-if="usingQuotaCredit && !post_pay">
        You have enough credits to complete this transaction!
      </p>

      <strong> Order Summary </strong>
      <div class="d-flex justify-space-between title-font mt-1">
        <small>Description</small>
        <small>Amount</small>
      </div>
      <v-divider class="mb-3" />
      <div class="d-flex justify-space-between title-font">
        <h4 class="black--text ml-3">{{ item.description }}</h4>
        <p class="mb-0" v-if="usingQuotaCredit || post_pay">
          {{ item.quantity }} {{ toTitleCase(item.product_code) }} Credit
        </p>
        <p class="mb-0" v-if="!usingQuotaCredit && !post_pay">
          ${{ item.price }} {{ item.currency }}
        </p>
      </div>
      <v-divider class="my-3" />
      <div class="title-font text-right">
        <h3>Total Due:</h3>
        <p class="pricing-card-price mt-2 mb-4">
          <span v-if="usingQuotaCredit && !post_pay">
            {{ item.quantity }} {{ toTitleCase(item.product_code) }} Credit
          </span>
          <span v-if="post_pay">
            No Credits Due<span
              style="font-size: medium; vertical-align: text-top"
              >*</span
            >
          </span>
          <span v-if="!usingQuotaCredit && !post_pay">
            ${{ item.price }} {{ item.currency }}
          </span>
        </p>
        <p v-if="post_pay">
          * This transaction will be charged to your post-paid account.
        </p>
      </div>
      <p v-if="!usingQuotaCredit && !post_pay">
        <strong>Payment Summary</strong>
        <PaymentMethodTable
          :paymentMethods="chosenPaymentMethod ? [chosenPaymentMethod] : []"
          :loading="initialising"
        />
      </p>

      <div v-if="usingQuotaCredit || post_pay" class="my-1 flex-column">
        <span class="d-block mb-1">
          {{ toTitleCase(item.product_code) }} Credits Remaining After
          Transaction - ( {{ quota.credits }} of
          {{ quota.starting + quota.manually_added }} )
        </span>
        <div
          class="flex-grow-1"
          :style="
            'display: grid; grid-template-columns: ' +
            `${quota.incoming}fr ` +
            `${quota.starting_rollover + quota.manually_added}fr;`
          "
        >
          <v-progress-linear
            :value="
              Math.floor(
                (Math.min(quota.incoming, quota.credits) / quota.incoming) * 100
              )
            "
          />
          <v-progress-linear
            color="success"
            :value="
              Math.floor(
                (Math.max(
                  0,
                  quota.starting_rollover +
                    quota.manually_added -
                    (quota.starting + quota.manually_added - quota.credits)
                ) /
                  (quota.starting_rollover + quota.manually_added)) *
                  100
              )
            "
          />
        </div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        v-if="!usingQuotaCredit && !post_pay"
        class="mr-2 elevation-0 rounded-10"
        :disabled="loading || initialising"
        @click="paymentSelectDialog = true"
      >
        CHANGE PAYMENT METHOD
      </v-btn>
      <v-btn
        class="btn-error"
        elevation="0"
        @click="dialog = false"
        :disabled="loading || initialising"
      >
        CANCEL
      </v-btn>
      <v-btn
        class="btn-primary"
        @click="emitConfirm"
        :disabled="initialising"
        :loading="loading"
      >
        CONFIRM {{ !usingQuotaCredit && !post_pay ? "AND PAY" : null }}
      </v-btn>
    </v-card-actions>
    <v-dialog
      v-model="paymentSelectDialog"
      width="700px"
      content-class="rounded-20"
    >
      <v-card class="rounded-20">
        <v-card-title class="title-font">
          Select a Payment Method
        </v-card-title>
        <PaymentMethodTable
          :paymentMethods="paymentMethods"
          @select="selectPaymentMethod"
        />
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import { toTitleCase } from "@/utils"
// import client from "@/lib/ApiClient"
import PaymentMethodTable from "@/components/shared/PaymentMethodTable.vue"
export default {
  name: "PurchaseCard",

  props: ["value", "item", "acquisitionMethod", "loading"],

  components: { PaymentMethodTable },

  data() {
    return {
      paymentSelectDialog: false,
      chosenPaymentMethod: null,
      initialising: true
    }
  },

  watch: {
    paymentMethods(val, prevVal) {
      if (val && prevVal === null) return this.initialise()
    },
    loading(val, prevVal) {
      if (!this.currentTenant.activated && val === false && prevVal === true) {
        this.setCurrentTenant({ ...this.currentTenant, activated: true })
        this.setSubscription({ ...this.subscription, activated: true })
      }
    }
  },

  computed: {
    ...mapGetters([
      "paymentMethods",
      "currentPlan",
      "currentQuotas",
      "currentTenant",
      "subscription"
    ]),

    dialog: {
      get() {
        return this.value
      },
      set(value) {
        return this.$emit("input", value)
      }
    },

    post_pay() {
      const planQuota = this.currentPlan.quotas.find(
        (q) => q.code === this.item.quota_code
      )
      return planQuota.post_pay
    },

    usingQuotaCredit() {
      return this.acquisitionMethod === "quota"
    },

    quota() {
      if (!this.usingQuotaCredit && !this.post_pay) return
      const quota = this.currentQuotas.find(
        (q) => q.quota_code === this.item.quota_code
      )

      return {
        credits: this.post_pay ? quota.current : quota.current - 1,
        starting: quota.starting,
        starting_rollover: quota.starting_rollover,
        manually_added: quota.manually_added,
        incoming: quota.incoming
      }
    }
  },

  methods: {
    ...mapActions(["setPaymentMethods", "setCurrentTenant", "setSubscription"]),

    toTitleCase,

    initialise() {
      this.chosenPaymentMethod = this.paymentMethods?.find((m) => m.default)
      this.initialising = false
    },

    selectPaymentMethod(paymentMethod) {
      this.chosenPaymentMethod = paymentMethod
      this.paymentSelectDialog = false
    },

    emitConfirm() {
      this.$emit("confirm", this.chosenPaymentMethod?.payment_id)
    }
  },

  mounted() {
    // Does this component ever need to trigger payment method reload?
    if (this.usingQuotaCredit || this.paymentMethods?.length || this.post_pay)
      this.initialise()
  }
}
</script>
