<template>
  <div>
    <v-form ref="orderForm">
      <v-row>
        <v-col cols="6">
          <FoSessionOption />
          <v-card class="rounded-20 ml-4 my-n2" flat>
            <MultiUnitConfirmation
              v-model="localForwardedOrderForm.implants"
              :interactive="true"
            />
          </v-card>
          <SpecialRequests v-model="localForwardedOrderForm.special_requests" />
        </v-col>

        <v-col cols="6" class="d-flex flex-column">
          <UploadProjectFiles v-model="files" />
          <v-spacer />
          <SubmitOrCancel @submit="handleSubmission" @back="$emit('back')" />
        </v-col>
      </v-row>
    </v-form>

    <PurchaseDialog
      v-model="purchaseDialog"
      :item="lineItem"
      :acquisitionMethod="acquisitionMethod"
      :loading="loading"
      @confirm="handleConfirm"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { deepCopy } from "@/utils"
import client from "@/lib/ApiClient"
import MultiUnitConfirmation from "@/components/shared/MultiUnitConfirmation.vue"
import SpecialRequests from "@/components/shared/SpecialRequests.vue"
import PurchaseDialog from "@/components/shared/PurchaseDialog.vue"
import UploadProjectFiles from "./ForwardedOrderOptions/UploadProjectFiles.vue"
import SubmitOrCancel from "./ForwardedOrderOptions/SubmitOrCancel.vue"
import FoSessionOption from "../shared/FoSessionOption.vue"

export default {
  name: "ForwardedOrderOptions",

  components: {
    MultiUnitConfirmation,
    UploadProjectFiles,
    SubmitOrCancel,
    SpecialRequests,
    PurchaseDialog,
    FoSessionOption
  },

  data() {
    return {
      files: [],
      purchaseDialog: false,
      loading: false,
      acquisitionStrategy: null,
      localForwardedOrderForm: {}
    }
  },

  computed: {
    ...mapGetters([
      "country",
      "products",
      "currentPlan",
      "selectedOrder",
      "selectedOrderSession",
      "selectedForwardedOrder",
      "forwardedOrderForm"
    ]),

    lineItem() {
      const product = this.products.find((p) => p.product_code === "BAR")
      return {
        description: "Milled Bar",
        quantity: 1,
        currency: this.currentPlan?.subscription_currency,
        price: product?.price,
        product_code: "BAR",
        quota_code: "bars"
      }
    },

    acquisitionMethod() {
      return this.acquisitionStrategy?.find((s) => s.product_code === "BAR")
        .method
    }
  },

  watch: {
    files: {
      handler(value) {
        this.localForwardedOrderForm.file_uids = value
          .filter((item) => item !== null)
          .map((item) => item.uid)
      },
      deep: true
    },
    localForwardedOrderForm: {
      async handler(value) {
        if (!value || !this.selectedForwardedOrder) return
        const response = await client.instance.orders.updateForwardedOrder(
          this.selectedOrder.uid,
          this.selectedOrderSession.uid,
          this.selectedForwardedOrder.uid,
          value
        )
        this.setSelectedForwardedOrder(response)
      },
      deep: true
    }
  },

  methods: {
    ...mapActions([
      "setSelectedForwardedOrder",
      "setForwardedOrderForm",
      "triggerReload"
    ]),

    async createForwardedOrder() {
      const forwardedOrder = await client.instance.orders.createForwardedOrder(
        this.selectedOrder.uid,
        this.forwardedOrderForm
      )
      this.setSelectedForwardedOrder(forwardedOrder)
    },
    async handleContinue() {
      this.loading = true
      await this.createForwardedOrder()
      this.loading = false
      return this.workflowStep++
    },

    async handleSubmission() {
      if (!this.$refs.orderForm.validate()) {
        return
      }
      this.loading = true
      const purchaseForm = {
        items: [
          {
            product_code: "BAR",
            quantity: 1
          }
        ],
        payment_type: "stripe",
        order_uid: this.selectedOrder.uid
      }
      this.acquisitionStrategy = await client.gateway.billing.checkStrategy(
        purchaseForm
      )
      this.loading = false
      this.purchaseDialog = true
    },

    async handleConfirm(paymentMethodId) {
      this.loading = true
      const purchaseForm = {
        items: [
          {
            product_code: "BAR",
            quantity: 1
          }
        ],
        payment_type: "stripe",
        payment_id: paymentMethodId,
        order_uid: this.selectedOrder.uid,
        metadata: {
          forwarded_order_uid: this.selectedForwardedOrder.uid
        }
      }
      try {
        await client.gateway.billing.commitPurchase(purchaseForm)
      } catch {
        // error states
        console.error("Failed to submit Bar Order")
        this.loading = false
        this.purchaseDialog = false
        return
      }

      if (this.acquisitionMethod === "quota") {
        this.triggerReload("quotas")
      }
      this.triggerReload("forwardedOrder")
      this.triggerReload("forwardedOrders")
      this.triggerReload("order")
      this.loading = false
      this.purchaseDialog = false
      this.$emit("next")
    }
  },

  beforeMount() {
    this.localForwardedOrderForm = deepCopy(this.forwardedOrderForm)
  }
}
</script>
