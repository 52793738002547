<template>
  <v-card flat>
    <v-card-title class="title-font">
      <v-icon v-if="!optional" :color="file ? 'success' : 'error'" class="mr-2">
        {{ file ? "mdi-check-circle" : "mdi-close-circle" }}
      </v-icon>
      <v-icon v-if="optional" color="secondary" class="mr-2">
        mdi-minus-circle
      </v-icon>
      {{ categoryTitle }}
      <small v-if="categorySubtitle" class="ml-3"
        >{{ categorySubtitle }}
      </small>
      <small v-if="optional" class="ml-3">(Optional) </small>
    </v-card-title>
    <v-card-subtitle class="error--text ml-3" v-if="error">
      Required
    </v-card-subtitle>
    <v-card-text>
      <v-row>
        <v-col cols="5">
          <ExistingFileUploader v-model="file" :category="category" />
        </v-col>
        <v-col cols="5">
          <FileUploader
            v-model="file"
            text="Upload"
            :category="category"
            :fileType="fileType"
          />
        </v-col>
        <v-col cols="2">
          <v-badge
            bordered
            color="error"
            icon="mdi-close"
            overlap
            right
            @click.native="removeFile"
            v-if="file"
          >
            <v-tooltip top class="rounded-10">
              <template v-slot:activator="{ on, attrs }">
                <v-card
                  outlined
                  class="rounded-10 grey-border overflow-hidden d-flex flex-column my-auto"
                  height="86"
                  width="86"
                  v-bind="attrs"
                  v-on="on"
                  @click.stop
                  :ripple="false"
                >
                  <ModelViewer
                    v-if="fileType === 'scan' && file"
                    :filename="file.file_name"
                    :url="file.url"
                    :height="80"
                    :width="80"
                    class="pa-0 ma-0"
                  />
                  <v-img
                    v-if="fileType === 'photo' && file"
                    contain
                    height="100%"
                    :src="file.url"
                  />
                  <v-icon
                    large
                    v-if="fileType === 'other' && file"
                    class="my-auto mx-auto"
                    >mdi-paperclip</v-icon
                  >
                </v-card>
              </template>
              <span>{{ formatOFTFilename(file.file_name) }}</span>
            </v-tooltip>
          </v-badge>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import client from "@/lib/ApiClient"
import { formatOFTFilename } from "@/utils"
import ExistingFileUploader from "./FileUploadAndPreview/ExistingFileUploader.vue"
import FileUploader from "./FileUploadAndPreview/FileUploader.vue"
import ModelViewer from "@/components/shared/ModelViewer.vue"

export default {
  name: "FileUploadAndPreview",

  props: [
    "value",
    "categoryTitle",
    "category",
    "categorySubtitle",
    "fileType",
    "optional"
  ],

  inject: ["form"],

  components: { ExistingFileUploader, FileUploader, ModelViewer },

  data() {
    return {
      error: null
    }
  },

  computed: {
    file: {
      get() {
        return this.value
      },

      async set(value) {
        if (value) {
          this.error = null
          await client.oft.getFileUrls(value)
        }
        this.$emit("input", value)
      }
    }
  },

  methods: {
    formatOFTFilename,

    validate() {
      if (!this.file && !this.optional) {
        this.error = "Must be selected"
        return false
      }
      return true
    },

    removeFile() {
      this.file = null
    }
  },

  created() {
    this.form.register(this)
  }
}
</script>
