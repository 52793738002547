<template>
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.7917 3.125V3.125C20.0548 3.125 20.1864 3.125 20.297 3.13874C21.1151 3.2404 21.7596 3.88489 21.8613 4.70304C21.875 4.81359 21.875 4.94517 21.875 5.20833V16.9117C21.875 17.9198 21.875 18.4238 21.6788 18.8088C21.5062 19.1475 21.2309 19.4229 20.8922 19.5955C20.5071 19.7917 20.0031 19.7917 18.995 19.7917H6.005C4.99691 19.7917 4.49286 19.7917 4.10782 19.5955C3.76913 19.4229 3.49376 19.1475 3.32119 18.8088C3.125 18.4238 3.125 17.9198 3.125 16.9117V5.20833C3.125 4.94517 3.125 4.81359 3.13874 4.70304C3.2404 3.88489 3.88489 3.2404 4.70304 3.13874C4.81359 3.125 4.94517 3.125 5.20833 3.125V3.125"
      :stroke="color"
      stroke-width="1.5"
    />
    <path
      d="M19.7916 3.125H18.8729C18.3419 3.125 18.0765 3.125 17.856 3.24247C17.8184 3.26254 17.782 3.285 17.7472 3.30971C17.5436 3.45436 17.4249 3.6918 17.1874 4.16667V4.16667C16.95 4.64154 16.8313 4.87897 16.6276 5.02362C16.5928 5.04833 16.5565 5.07079 16.5188 5.09086C16.2983 5.20833 16.0329 5.20833 15.502 5.20833H9.28811C8.84256 5.20833 8.61979 5.20833 8.43016 5.12652C8.31926 5.07867 8.21794 5.01113 8.13111 4.92715C7.98266 4.78358 7.89698 4.57794 7.72561 4.16667V4.16667C7.55425 3.75539 7.46856 3.54975 7.32011 3.40618C7.23329 3.32221 7.13197 3.25466 7.02106 3.20681C6.83144 3.125 6.60866 3.125 6.16311 3.125H5.20825"
      :stroke="color"
      stroke-width="1.5"
    />
    <path
      d="M21.875 8.3335H18.873C18.342 8.3335 18.0766 8.3335 17.8561 8.45096C17.8184 8.47103 17.7821 8.4935 17.7473 8.51821C17.5437 8.66286 17.4249 8.90029 17.1875 9.37516V9.37516C16.9501 9.85003 16.8313 10.0875 16.6277 10.2321C16.5929 10.2568 16.5566 10.2793 16.5189 10.2994C16.2984 10.4168 16.033 10.4168 15.502 10.4168H9.28819C8.84264 10.4168 8.61987 10.4168 8.43024 10.335C8.31934 10.2872 8.21802 10.2196 8.13119 10.1357C7.98274 9.99208 7.89706 9.78644 7.72569 9.37516V9.37516C7.55433 8.96389 7.46865 8.75825 7.32019 8.61467C7.23337 8.5307 7.13205 8.46316 7.02114 8.41531C6.83152 8.3335 6.60874 8.3335 6.16319 8.3335H3.125"
      :stroke="color"
      stroke-width="1.5"
    />
    <path
      d="M21.875 13.5415H18.873C18.342 13.5415 18.0766 13.5415 17.8561 13.659C17.8184 13.679 17.7821 13.7015 17.7473 13.7262C17.5437 13.8709 17.4249 14.1083 17.1875 14.5832V14.5832C16.9501 15.058 16.8313 15.2955 16.6277 15.4401C16.5929 15.4648 16.5566 15.4873 16.5189 15.5074C16.2984 15.6248 16.033 15.6248 15.502 15.6248H9.28819C8.84264 15.6248 8.61987 15.6248 8.43024 15.543C8.31934 15.4952 8.21802 15.4276 8.13119 15.3437C7.98274 15.2001 7.89706 14.9944 7.72569 14.5832V14.5832C7.55433 14.1719 7.46865 13.9663 7.32019 13.8227C7.23337 13.7387 7.13205 13.6712 7.02114 13.6233C6.83152 13.5415 6.60874 13.5415 6.16319 13.5415H3.125"
      :stroke="color"
      stroke-width="1.5"
    />
  </svg>
</template>
<script>
export default {
  name: "InboxArchive",
  props: {
    color: {
      type: String,
      default: "#333F48"
    }
  }
}
</script>
