<template>
  <v-row>
    <v-col cols="12">
      <v-card flat class="rounded-20">
        <v-card-text class="black--text">
          <p>To continue with abutment export, confirm the choices below.</p>
          Choose the desired Scan Body to match your desired library for
          geometry and design purposes.
        </v-card-text>
      </v-card>
    </v-col>

    <v-col xl="6" lg="6" md="12" sm="12">
      <v-card class="rounded-20" flat min-width="340" height="460">
        <v-card-title class="title-font"> SCAN BODY </v-card-title>
        <v-row>
          <v-col align="center">
            <v-select
              v-model="scanBodyComponentId"
              :disabled="!exportsAvailable && !isOwner"
              label="Scan Body Library for Export"
              :items="mappedScanBodies"
              outlined
              dense
              hide-details
              class="mx-4"
              :menu-props="{ rounded: '10' }"
            />
            <ModelViewer
              v-if="scanBodyMesh"
              :filename="scanBodyMesh.filename"
              :url="scanBodyMesh.url"
              :width="340"
              :height="340"
            />
          </v-col>
        </v-row>
      </v-card>
    </v-col>

    <v-col xl="6" lg="6" md="12" sm="12">
      <v-card class="rounded-20" flat min-width="340" height="460">
        <v-card-title class="title-font"> HEALING CAP </v-card-title>
        <v-row>
          <v-col align="center">
            <v-row>
              <v-col class="ml-6 d-flex align-center">
                <v-switch
                  inset
                  v-model="enableHealingCap"
                  hide-details
                  class="mt-0 py-2"
                />
                <v-select
                  v-show="enableHealingCap"
                  v-model="healingCapComponentId"
                  :disabled="
                    (!exportsAvailable && !isOwner) || !enableHealingCap
                  "
                  label="Healing Cap Library for Export"
                  :items="mappedHealingCaps"
                  outlined
                  dense
                  hide-details
                  class="mx-4"
                  :menu-props="{ rounded: '10' }"
                />
              </v-col>
            </v-row>
            <ModelViewer
              v-if="healingCapMesh"
              :filename="healingCapMesh.filename"
              :url="healingCapMesh.url"
              :height="340"
              :width="340"
            />
          </v-col>
        </v-row>
      </v-card>
    </v-col>

    <v-col cols="12">
      <v-card flat class="rounded-20">
        <v-card-actions>
          <v-scale-transition origin="center center">
            <div
              class="flex-grow-1 ml-2 mr-3"
              v-if="loading"
              style="width: 100%"
            >
              <v-progress-linear
                rounded
                indeterminate
                color="primary"
                height="36"
                class="rounded-10"
                background-opacity="0.6"
              >
                <span
                  class="text-body-2 font-weight-medium white--text loading-pulse"
                >
                  PROCESSING...
                </span>
              </v-progress-linear>
            </div>
          </v-scale-transition>
          <v-spacer />
          <v-btn class="btn-error" elevation="0" @click="cancelDownload">
            CANCEL
          </v-btn>
          <v-btn
            class="btn-primary white--text"
            :disabled="
              (!exportsAvailable && !isOwner) || !downloadEnabled || loading
            "
            @click="requestDownload"
          >
            DOWNLOAD
          </v-btn>
        </v-card-actions>

        <PurchaseDialog
          v-model="dialog"
          :item="lineItem"
          :acquisitionMethod="acquisitionMethod"
          :loading="loading"
          @confirm="consumeExport"
        />
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { v4 as uuid } from "uuid"
import { mapActions, mapGetters } from "vuex"
import { delay } from "@/utils"
import client from "@/lib/ApiClient"
import PurchaseDialog from "@/components/shared/PurchaseDialog.vue"
import ModelViewer from "@/components/shared/ModelViewer.vue"

export default {
  name: "ExportAbutment",

  components: { PurchaseDialog, ModelViewer },

  data() {
    return {
      dialog: false,
      loading: false,
      enableHealingCap: false,
      scanBodyComponentId: null,
      healingCapComponentId: null,
      scanBodyMesh: null,
      healingCapMesh: null,
      // Perhaps move meshCache to store.
      // Perhaps fetch all meshes upfront?
      meshCache: [],
      acquisitionStrategy: null,
      exportUid: null
    }
  },

  async mounted() {
    if (!this.exportComponents) this.fetchExportComponents()
  },

  computed: {
    ...mapGetters([
      "isOwner",
      "country",
      "products",
      "currentPlan",
      "currentQuotas",
      "selectedOrder",
      "selectedOrderSession",
      "exportComponents"
    ]),

    lineItem() {
      const product = this.products.find((p) => p.product_code === "EXPORT")
      return {
        description: "Abutment Position Export",
        quantity: 1,
        currency: this.currentPlan?.subscription_currency,
        price: product?.price,
        product_code: "EXPORT",
        quota_code: "exports"
      }
    },

    acquisitionMethod() {
      return this.acquisitionStrategy?.find((s) => s.product_code === "EXPORT")
        .method
    },

    exportsAvailable() {
      const exports = this.currentQuotas.find((q) => q.quota_code === "exports")
      return exports.current
    },

    mappedScanBodies() {
      if (!this.exportComponents) return []
      return this.exportComponents
        .filter((c) => c.type === "Scan Body")
        .map((c) => {
          return {
            text: c.name,
            value: c.id
          }
        })
    },

    mappedHealingCaps() {
      if (!this.exportComponents) return []
      return this.exportComponents
        .filter((c) => c.type === "Healing Cap")
        .map((c) => {
          return {
            text: c.name,
            value: c.id
          }
        })
    },

    downloadEnabled() {
      if (this.enableHealingCap)
        return this.scanBodyComponentId && this.healingCapComponentId
      else return !!this.scanBodyComponentId
    }
  },

  watch: {
    scanBodyComponentId(val) {
      if (val) {
        this.fetchExportMesh("scanBody")
      }
    },
    healingCapComponentId(val) {
      if (val) {
        this.fetchExportMesh("healingCap")
      }
    },
    enableHealingCap(val) {
      if (!val) {
        this.healingCapComponentId = null
        this.healingCapMesh = null
      }
    }
  },

  methods: {
    ...mapActions(["triggerReload", "setExportComponents"]),

    async fetchExportComponents() {
      this.loading = true
      this.setExportComponents(
        await client.gateway.components.fetchExportComponents()
      )
      this.loading = false
    },

    async fetchExportMesh(type) {
      const typeId = type + "ComponentId"
      const typeMesh = type + "Mesh"

      const cachedMesh = this.meshCache.find(
        (m) => m.component_id === this[typeId]
      )
      if (cachedMesh) return (this[typeMesh] = cachedMesh)

      this.loading = true
      this[typeMesh] = await client.gateway.components.fetchExportMesh(
        this[typeId]
      )
      this.meshCache.push(this[typeMesh])
      this.loading = false
    },

    async requestDownload() {
      if (!this.downloadEnabled) return
      this.loading = true

      if (this.selectedOrder.export_token_uid) {
        this.exportUid = uuid()
        await client.gateway.billing.consumeToken(
          this.selectedOrder.export_token_uid,
          {
            order_session_uid: this.selectedOrderSession.uid,
            export_uid: this.exportUid,
            nc_session_id: this.selectedOrderSession.nc_session_id,
            scan_body_id: this.scanBodyComponentId,
            healing_cap_id: this.healingCapComponentId
          }
        )
        await this.pollExport()
        this.$emit("exported", this.exportUid)
        this.exportUid = null
      } else {
        const payload = {
          items: [
            {
              product_code: "EXPORT",
              quantity: 1
            }
          ],
          payment_type: "stripe",
          order_uid: this.selectedOrder.uid
        }

        this.acquisitionStrategy = await client.gateway.billing.checkStrategy(
          payload
        )

        this.dialog = true
      }

      this.loading = false
    },

    async pollExport() {
      const ready = await client.instance.exports.checkExport(
        this.selectedOrderSession.uid,
        this.exportUid
      )
      if (!ready) {
        await delay(1000)
        return this.pollExport()
      } else this.triggerReload(["exports"])
    },

    async consumeExport(paymentMethodId) {
      this.loading = true
      this.exportUid = uuid()

      const payload = {
        // Receive product code from gateway
        // quantity from gateway endpoint?
        // payment type from default payment method?
        items: [
          {
            product_code: "EXPORT",
            quantity: 1
          }
        ],
        payment_type: "stripe",
        payment_id: paymentMethodId,
        order_uid: this.selectedOrder.uid,
        order_session_uid: this.selectedOrderSession.uid,
        metadata: {
          nc_session_id: this.selectedOrderSession.nc_session_id,
          export_uid: this.exportUid,
          scan_body_id: this.scanBodyComponentId,
          healing_cap_id: this.healingCapComponentId
        }
      }

      try {
        await client.gateway.billing.commitPurchase(payload)
      } catch {
        // Error states? e.g. toast indicating declined card?
        console.error("Failed to redeem Export Abutment")
        this.dialog = false
        this.loading = false
        return
      }
      await this.pollExport()

      this.triggerReload("order")
      this.triggerReload("exports")
      this.$emit("exported", this.exportUid)
      this.exportUid = null

      if (this.acquisitionMethod === "quota") {
        this.triggerReload("quotas")
      }

      this.dialog = false
      this.loading = false
    },

    cancelDownload() {
      this.$emit("cancelled")
    }
  }
}
</script>
