<template>
  <v-card flat class="card-bg pb-4 rounded-20">
    <v-toolbar color="transparent" flat height="40">
      <v-toolbar-title v-if="filter === 'inbox'" class="pt-3">
        <v-icon>$inboxreceived</v-icon>
        INBOX
      </v-toolbar-title>
      <v-toolbar-title v-if="filter === 'exported'" class="pt-3">
        <v-icon>$implantexport</v-icon>
        IMPLANTS EXPORTED
      </v-toolbar-title>
      <v-toolbar-title v-if="filter === 'waiting'" class="pt-3">
        <v-icon>$alarm</v-icon>
        AWAITING RESPONSE
      </v-toolbar-title>
      <v-toolbar-title v-if="filter === 'all'" class="pt-3">
        <v-icon>$inbox</v-icon>
        ALL CASES
      </v-toolbar-title>
      <v-toolbar-title v-if="filter === 'archived'" class="pt-3">
        <v-icon>$inboxarchive</v-icon>
        ARCHIVED CASES
      </v-toolbar-title>
      <v-toolbar-title v-if="filter === 'design'" class="pt-3">
        <v-icon>$toothdesigns</v-icon>
        DESIGN ONLY
      </v-toolbar-title>
      <v-toolbar-title v-if="filter === 'bar'" class="pt-3">
        <v-icon>$milledbar</v-icon>
        MILLED BARS
      </v-toolbar-title>
      <v-toolbar-title v-if="filter === 'finished_case'" class="pt-3">
        <v-icon>$finishedprosthetic</v-icon>
        FINISHED CASES
      </v-toolbar-title>
    </v-toolbar>
    <v-row class="px-4 py-2 my-0">
      <v-col class="py-1 my-0" xl="9" lg="8" md="12">
        <v-text-field
          v-model="inboxFilter"
          class="py-0"
          label="Search"
          placeholder="Search"
          solo
          dense
          flat
          prepend-inner-icon="mdi-magnify"
          hide-details
          background-color="white"
        />
      </v-col>
      <v-col class="py-1 my-0" xl="3" lg="4" md="12">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="dateRange"
          transition="scale-transition"
          offset-y
          min-width="auto"
          rounded="rounded-lg"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="dateRangeText"
              label="Date Range"
              class="py-0"
              readonly
              solo
              dense
              flat
              prepend-inner-icon="mdi-calendar"
              hide-details
              background-color="white"
              v-bind="attrs"
              v-on="on"
              clearable
              @click:clear="dateRange = []"
            />
          </template>
          <v-date-picker v-model="dateRange" range no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
            <v-btn
              text
              color="primary"
              @click="
                $refs.menu.save(dateRange)
                currentPage = 0
              "
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-card flat color="transparent" class="mx-4 rounded-10">
      <div
        v-if="loading && displayedItems.length === 0"
        class="text-center ma-5"
      >
        <LoadingSpinner />
      </div>
      <div
        v-if="!loading && displayedItems.length === 0"
        class="text-center my-10"
      >
        <v-icon class="mr-2">mdi-information</v-icon>
        No Cases Found
      </div>
      <template v-for="(item, index) in displayedItems">
        <OrderCard
          class="pa-5"
          :key="`${index}-${item.uid.slice(0, 6)}`"
          :order="item"
          @open="viewInboxItem"
        />
      </template>
      <v-card-actions>
        <span v-if="$vuetify.breakpoint.mdAndUp" class="text-subtitle-2 mx-4"
          >Items per page:
          <v-btn
            small
            text
            :color="itemsPerPage === 10 ? 'primary' : ''"
            @click="itemsPerPage = 10"
            >10</v-btn
          >
          <v-btn
            small
            text
            :color="itemsPerPage === 25 ? 'primary' : ''"
            @click="itemsPerPage = 25"
            >25</v-btn
          >
          <v-btn
            small
            text
            :color="itemsPerPage === 50 ? 'primary' : ''"
            @click="itemsPerPage = 50"
            >50</v-btn
          >
        </span>
        <v-spacer />
        <span class="text-subtitle-2">
          {{ displayedItems.length === 0 ? 0 : displayedItemsStartIdx + 1 }} -
          {{ displayedItemsEndIdx }} of {{ filteredOrders.length }}
        </span>
        <v-btn icon @click="currentPage--" :disabled="currentPage === 0">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn
          icon
          @click="currentPage++"
          :disabled="currentPage === pages - 1 || displayedItems.length === 0"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
    <MergeDialog v-model="orderToAssign" />
  </v-card>
</template>

<script>
import { mapActions } from "vuex"
import client from "@/lib/ApiClient"
import OrderCard from "./InboxItems/OrderCard.vue"
import MergeDialog from "./InboxItems/MergeDialog.vue"

export default {
  name: "InboxItems",

  props: ["value", "inboxItems", "loading", "filter"],

  components: {
    OrderCard,
    MergeDialog
  },

  data() {
    return {
      inboxFilter: null,
      currentPage: 0,
      itemsPerPage: 10,
      dateRange: [],
      menu: false,
      orderToAssign: null
    }
  },

  computed: {
    pages() {
      return Math.ceil(this.filteredOrders.length / this.itemsPerPage)
    },

    displayedItemsStartIdx() {
      return this.currentPage * this.itemsPerPage
    },

    displayedItemsEndIdx() {
      return this.displayedItemsStartIdx + this.itemsPerPage >
        this.filteredOrders.length
        ? this.filteredOrders.length
        : this.displayedItemsStartIdx + this.itemsPerPage
    },

    displayedItems() {
      return this.filteredOrders.slice(
        this.displayedItemsStartIdx,
        this.displayedItemsEndIdx
      )
    },

    filteredOrders() {
      let filteredItems = [...this.inboxItems].sort(
        (b, a) => new Date(a.created_at) - new Date(b.created_at)
      )
      if (this.inboxFilter) {
        filteredItems = filteredItems.filter(
          (item) =>
            item.order_number.toString().includes(this.inboxFilter) ||
            item.client_name
              .toLowerCase()
              .includes(this.inboxFilter.toLowerCase()) ||
            item.patient_reference
              .toLowerCase()
              .includes(this.inboxFilter.toLowerCase())
        )
      }
      if (this.dateRangeModifier.length == 2) {
        filteredItems = filteredItems.filter(
          (item) =>
            new Date(new Date(item.created_at).toISOString().slice(0, 10)) >=
              this.dateRangeModifier[0] &&
            new Date(new Date(item.created_at).toISOString().slice(0, 10)) <=
              this.dateRangeModifier[1]
        )
      }
      return filteredItems
    },

    dateRangeModifier() {
      return this.dateRange.map((date) => new Date(date)).sort((a, b) => a - b)
    },

    dateRangeText() {
      if (this.dateRangeModifier.length === 1) {
        return this.dateRangeModifier[0].toLocaleDateString()
      } else if (this.dateRange.length === 2) {
        return (
          this.dateRangeModifier[0].toLocaleDateString() +
          " - " +
          this.dateRangeModifier[1].toLocaleDateString()
        )
      } else return ""
    }
  },

  methods: {
    ...mapActions(["setSelectedOrder"]),

    dateModifier(date) {
      let hoursDiff = Math.abs(date - new Date()) / 3600000
      if (hoursDiff > 12) {
        return date.toLocaleDateString()
      } else {
        return Math.floor(hoursDiff).toString().concat(" hr")
      }
    },

    viewInboxItem(item) {
      if (item.assigned) {
        item.viewed = true
        client.instance.orders.viewOrder(item.uid)
        this.setSelectedOrder(item)
        this.$router.push({ name: "caseView", params: { uid: item.uid } })
      } else {
        this.orderToAssign = item
      }
    }
  },

  watch: {
    inboxFilter() {
      this.currentPage = 0
    }
  }
}
</script>
