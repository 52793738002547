import * as Sentry from "@sentry/vue"
import Vue from "vue"

export default function initSentry() {
  if (process.env.VUE_APP_SENTRY_ENV != "development") {
    Sentry.init({
      Vue,
      dsn: "https://14938a758f475199c0ba4de74db82cba@o1047672.ingest.us.sentry.io/4508758438117377",
      integrations: [Sentry.replayIntegration()],
      environment: process.env.VUE_APP_SENTRY_ENV,
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
  }
}
